import React from 'react';
import { Link } from 'react-router-dom';
import "./HomeimgStyle.css";

const HomeImg = () => {
  return (
    <div>
      <div id="banner" className="home-banner">
        <img
          src="https://wallpapers.com/images/hd/database-info-exchange-03ghlfed30kkhvnj.jpg"
          alt="Database"
          className="home-banner-img"
          data-testid="guesthomepage_homebanner"
        />
        <div className="op">
        <h1 className="home-banner-heading">Explore Owr Website Now</h1>
          {/* <h1 className="home-banner-heading">Managed Cloud</h1> */}
          {/* <h1 className="home-banner-heading">Managed Security</h1>
          <h1 className="home-banner-heading">Managed N/W Infra</h1>
          <h1 className="home-banner-heading">Artificial Intelligence</h1> */}
        </div>
      </div>
    </div>
  );
}

export default HomeImg;
