import React, { useState, useEffect } from 'react';
import './HeaderStyle.css';

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Check if the screen size is less than or equal to 768px (mobile view)
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check for mobile view on component mount
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Always toggle the sidebar state

    // Close sidebar if it's not mobile view
    if (!isMobileView) {
      setIsSidebarOpen(false);
    }
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div>
      <header className="headerHeadsr25">
        <nav className="navbar-leftHeadsr25">
          <a href="/" className="nav-item-headHeadsr25">
          <img src="https://i.postimg.cc/261Mw62H/Microsoft-Teams-image-11.png" alt="Logo" className="logoHeadsr25" /></a>
        </nav>
        <nav className="navbar-rightHeadsr25">
          <a href="/Aboutus" className="nav-itemHeadsr25">About</a>
          <a href="/Services" className="nav-itemHeadsr25">Services</a>
          <a href="/Blog" className="nav-itemHeadsr25">Blog</a>
          <a href="/Contactus" className="nav-itemHeadsr25">Contact Us</a>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            fill="currentColor" 
            className="bi bi-search search-iconHeadsr25" 
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </nav>
        <div className='side25'>
          {isMobileView && ( // Show toggle button only for mobile view
            <button className="sidebar-toggleHeadsr25" onClick={toggleSidebar}>
              ☰
            </button>
          )}
        </div>
      </header>
      {isSidebarOpen && (
        <div className="sidebarHeadsr25">
          <button className="close-sidebarHeadsr25" onClick={closeSidebar}>✕</button>
          <a href="/Aboutus" className="nav-item-sidebarHeadsr25">About</a>
          <a href="/Services" className="nav-item-sidebarHeadsr25">Services</a>
          <a href="/Blog" className="nav-item-sidebarHeadsr25">Blog</a>
          <a href="/Contactus" className="nav-item-sidebarHeadsr25">Contact Us</a>
          <a href="#" className="nav-item-sidebarHeadsr25">Search</a>
        </div>
      )}
    </div>
  );
}

export default Header;
