import React from 'react'
import{BrowserRouter,Routes,Route} from "react-router-dom";

import Contactus from './Component/Contactus/Contactus';
import Header from './Component/Header';
import HomeImg from './Component/Homeimg';
import Homepage from './Component/Homepage';

import Footer from './Component/Footer';
import Login from './Component/Login';

import Signup from './Component/Signup';



import Review from './Component/Review';

import Industry from './Component/Industries/Industry';
import Business from './Component/Business/Business';
import ServiceM from './Component/Services/ServiceM';
import Testimonials from './Component/Testimonials/Testimonials';
import Stats from './Component/Stats/Stats';
import Aboutus from './Component/About/Aboutus';
import ManagedCloud from './Component/Services/ManagedCloud';




const App = () => {
  return (
    <div>
     <BrowserRouter>
        <Header/>
        <Routes>
         
          <Route path='/' exact element={<Homepage/>}></Route>
          <Route path='/Industry' exact element={<Industry/>}></Route>
          <Route path='/Business' exact element={<Business/>}></Route>
          <Route path='/ServiceM' exact element={<ServiceM/>}></Route>
          <Route path='/Testimonials' exact element={<Testimonials/>}></Route>
          <Route path='/Stats' exact element={<Stats/>}></Route>
          <Route path='/Aboutus' exact element={<Aboutus/>}></Route>
          <Route path='/ManagedCloud' exact element={<ManagedCloud/>}></Route>




          <Route path='/Header' exact element={<Header/>}></Route>
          <Route path='/Contactus' exact element={<Contactus/>}></Route>
       
          <Route path='/Homeimg' exact element={<HomeImg/>}></Route>
        
          <Route path='/Footer' exact element={<Footer/>}></Route>
         
          <Route path='/Login' exact element={<Login/>}></Route>
          <Route path='/Signup' exact element={<Signup/>}></Route>
    
          
         
          <Route path='/Review' exact element={<Review/>}></Route>
         
       </Routes>
        <Footer/>
      </BrowserRouter>
      
    </div>
  )
}

export default App




