import React from 'react'
import "./Testimonials.css"

const Testimonials = () => {
  return (
    <div className='containerTest2'>
      <h1 className='headTest2'>Testimonials</h1>
      <div className='backgroundImgTest2'>
        <div className='contentTest2'>
          <h2 className='subheadTest2' style={{color:"white"}}>What Our Clients Say</h2>
          <div className='specialCharTest2'style={{color:"#ff6600"}}>❝</div>
          <h3 className='titleTest2'style={{color:"white"}}>Great work</h3>
          <p className='descTest2'style={{color:"white"}}>
            Our business requires an extensive amount of storage and initially, we depended on local providers in exchange for fortune. It was then I came across BENITSOFT-TECH and they did save me a great time and money! Their expert team guided us from start to finish until! Great effort team.
          </p>
          <p className='nameTest2'style={{color:"white"}}>Suman Raj</p>
          <p className='designationTest2'style={{color:"#ff6600"}}>---Software Engineer</p>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
