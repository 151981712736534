import React from 'react'
import "./Industry.css"

const Industry = () => {
  return (
    <div className='containerInd2'>
    <h1 className='head2'>INDUSTRIES</h1>

    <p className='pgraph2'>We have established trusted partnerships
     with a range of Fortune 500 companies and mid-sized banking and 
     financial service organizations. They have consistently turned 
     to us for our technical and domain expertise, leveraging our
      support at various stages of their project lifecycle.</p>

    </div>
  )
}

export default Industry
