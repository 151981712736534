import React from 'react';
import "./FooterStyle.css";

const Footer = () => {
  return (
    <footer className="footer-foot25">
      <div className="footer-container-foot25">
       
        <div className="footer-section-foot25">
          <h2 className="footer-heading-foot25">About Us</h2>
          <div className="footer-links-foot25">
            <p className="about-text-foot25">
            A globally acclaimed IT infrastructure and security consulting company with demonstrative expertise in Enterprise solutions, Cloud services, Cybersecurity, and AI.            </p>
          </div>
        </div>
        <div className="footer-section-foot25">
          <h2 className="footer-heading-foot25">Services</h2>
          <div className="footer-links-foot25">
            <a href="#">Managed Cloud</a>
            <a href="#">Managed Infra</a>
            <a href="#">Managed Security</a>
            <a href="#">Artificial Intelligence</a>
          </div>
        </div>
        <div className="footer-section-foot25">
          <h2 className="footer-heading-foot25">Address</h2>
          <div className="footer-linksk-foot25">
            <p className='add'>5th floor, Melkiors Pride,</p>
            <p className='add'>Vinayaka Nagar, HITEC City,</p>
            <p className='add'>Hyderabad, Telangana 500081</p>
            {/* <p className='add'>Koramangala, Bengaluru, Karnataka 560095</p> */}
          </div>
        </div>
        <div className="footer-section-foot25">
          <h2 className="footer-heading-foot25">Social Links</h2>
          <div className="footer-links-foot25">
            <a className='linksk' href="">GitHub</a>
            <a className='linksk' href="">Discord</a>
            <a className='linksk' href="">Twitter</a>
            <a className='linksk' href="">YouTube</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom-foot25">
        <span className="footer-copyright-foot25">
          © Copyright 1986. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
