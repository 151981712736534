import React from 'react';
import './ManagedCloud.css';

const ManagedCloud = () => {
  return (
    <div className="container-manage25">
      <img 
        src="https://cdn.pixabay.com/photo/2018/07/14/11/33/earth-3537401_1280.jpg" 
        alt="Full Screen Background" 
        className="fullscreen-img-manage25"
      />
      <div className="content-manage25">
        <h1 className="main-heading-manage25">Embrace And Scale the Cloud Revolution</h1>
        <h1 className="security-expert-heading-manage25">SPEAK TO OUR SECURITY EXPERT</h1>
        <h3 className="sub-heading-manage25">Bringing Our Managed Cloud Security Services as your first line of defense</h3>
        <p className="paragraph-manage25">
          Transform cloud security by integrating it seamlessly into the core of your organization. Adopt a fully managed, consumption-based, as-a-service model to meet the changing requirements of your business efficiently. This approach guarantees Business Continuity, Streamlined Compliance, and Enhanced Security.
          <br /><br />
          Revitalize cloud security by integrating it seamlessly into the core of your organization. Adopt a fully managed, consumption-based, as-a-service model to meet the changing requirements of your business efficiently. This approach guarantees Business Continuity, Streamlined Compliance, and Enhanced Security.
        </p>
        <h2 className="challenges-heading-manage25">Major Challenges Your Team Needs to Contemplate</h2>
        <p className="paragraph-manage25">
          During their digital transformation journey, businesses encounter threats that pose challenges to security teams striving to minimize risks and uphold compliance in expansive and dynamic multi-cloud landscapes. This overload frequently manifests as setbacks, including:
        </p>
        <ul className="key-points-manage25">
          <li>✔ A deficiency in a comprehensive cloud security approach results in regulatory compliance challenges.</li>
          <li>✔ Financial constraints or restrictions on in-house personnel contribute to unutilized resources and inefficient spending.</li>
          <li>✔ Lapses in deploying advanced security measures results in service interruptions or outages during unforeseen events such as cyber-attacks, DDoS & DoS attacks.</li>
        </ul>
        <p className="paragraph-manage25">
          Failure to implement advanced security measures results in service interruptions or outages during unexpected occurrences such as cyber-attacks, DDoS, and DoS attacks.
        </p>
        <h2 className="schedule-heading-manage25">SCHEDULE A DISCUSSION</h2>
        <p className="paragraph-manage25">Enhance Your Security Posture with Vendor-Agnostic Solutions & An Automated Approach.</p>
        <p className="paragraph-manage25">BENITSOFT-TECH recognizes the integral role of security, compliance, and business continuity as the core of an enterprise infrastructure.</p>
        <p className="paragraph-manage25">Regardless of the scale of your cloud operations, we provide an assurance of comprehensive end-to-end visibility.</p>
        <h2 className="managed-security-heading-manage25">Managed Security</h2>
        <p className="paragraph-manage25">
          Our multi-cloud security services operate around the clock (24/7/365), offering best practice recommendations, guidance, fully managed security, proactive defense, and remediation of security issues.
        </p>
      </div>
    </div>
  )
}

export default ManagedCloud;
