import React from 'react';
import "./Business.css";

const Business = () => {
  return (
    <div className='containerBus2'>
      <h3 className='HeadingBus2'>OVER 2K+ BUSINESSES GROWING WITH BENITSOFT-TECH</h3>
      <div className='logoSectionBus2'>
        <div className='logoContainerBus2'>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/Microsoft-white.png" alt="Company 1" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/red-hat-white.png" alt="Company 2" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/sentinal.png" alt="Company 3" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/sophos.png" alt="Company 4" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/veeam.png" alt="Company 5" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/veritas.png" alt="Company 6" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/zscaler.png" alt="Company 7" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/Dell.png" alt="Company 8" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/fortinet.png" alt="Company 9" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/manageengine_logo_black.png" alt="Company 10" className='logoBus2'/>
          {/* Duplicate logos for smooth scrolling */}
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/Microsoft-white.png" alt="Company 1" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/red-hat-white.png" alt="Company 2" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/sentinal.png" alt="Company 3" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/sophos.png" alt="Company 4" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/veeam.png" alt="Company 5" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/veritas.png" alt="Company 6" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/zscaler.png" alt="Company 7" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/Dell.png" alt="Company 8" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/fortinet.png" alt="Company 9" className='logoBus2'/>
          <img src="https://allianceproit.com/wp-content/uploads/2023/11/manageengine_logo_black.png" alt="Company 10" className='logoBus2'/>
        </div>
      </div>
    </div>
  )
}

export default Business;
