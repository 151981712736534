import React from 'react';
import "./ServiceM.css";

const ServiceM = () => {
  return (
    <div className='ContainerServ2'>
      <h1 className='headingServ2'>Managed Services</h1>
      <div className='servicesGridServ2'>
        <div className='serviceItemServ2'>
          <h2 className='serviceTitleServ2'>Managed Cloud</h2>
          <p className='serviceDescriptionServ2'>
            Optimize your cloud operations with our managed cloud services. Experience seamless integration and enhanced security. <a href='#' className='knowMoreServ2'>Know more</a>
          </p>
        </div>
        <div className='serviceItemServ2'>
          <h2 className='serviceTitleServ2'>Managed Infra</h2>
          <p className='serviceDescriptionServ2'>
            Ensure the stability and performance of your IT infrastructure with our expert managed services. Achieve operational efficiency. <a href='#' className='knowMoreServ2'>Know more</a>
          </p>
        </div>
        <div className='serviceItemServ2'>
          <h2 className='serviceTitleServ2'>Managed Security</h2>
          <p className='serviceDescriptionServ2'>
            Protect your business with our comprehensive managed security services. Stay ahead of threats and secure your data. <a href='#' className='knowMoreServ2'>Know more</a>
          </p>
        </div>
        <div className='serviceItemServ2'>
          <h2 className='serviceTitleServ2'>Managed Support</h2>
          <p className='serviceDescriptionServ2'>
            Get 24/7 support for your IT needs with our managed support services. Enhance productivity and reduce downtime. <a href='#' className='knowMoreServ2'>Know more</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServiceM;
