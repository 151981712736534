// import React, { useState } from 'react';
// import './SignupStyle.css';
// import { Link } from 'react-router-dom'


// function Signup() {
//   const [fullName, setFullName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [error, setError] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Validate form fields
//     if (fullName === '' || email === '' || phoneNumber === '' || password === '' || confirmPassword === '') {
//       setError('Please fill in all fields.');
//     } else if (password !== confirmPassword) {
//       setError('Passwords do not match.');
//     } else {
//       // Perform signup logic
//       // You can make an API call or implement your own signup functionality here
//       // For simplicity, let's just display a success message
//       alert('Signup successful!');
//       setFullName('');
//       setEmail('');
//       setPhoneNumber('');
//       setPassword('');
//       setConfirmPassword('');
//       setError('');
//     }
//   };

//   return (
//     <div>

// <section className="page-title" style={{ backgroundColor: "#20255A",marginTop:"70px" , padding:"50px"}}>
//         <div className="auto-container" style={{marginLeft:"510px"}}>
//           <h1 className="d-none d-lg-block d-xl-block d-md-block" style={{color:"white"}}>SIGNUP</h1>
//           <ul className="bread-crumb clearfix">
//             <li>
//               <Link to="/" style={{marginLeft:"50px"}}>Home</Link>
//             </li>
  
//           </ul>
//         </div>
//       </section>

// {/* <section className="heading-page" style={{marginTop:"70px"}}>
//   {" "}
//   <img src="https://ms-cloud.com/images/aboutus-bnr.jpg" alt="" />
//   <div className="container">
//     <div className="heading-page-content">
//       <div className="au-page-title">
//         <h1>Sign Up</h1>
//       </div>
//       <nav aria-label="breadcrumb">
//         <ul className="breadcrumb">
//           <li className="breadcrumb-item">
//             <a href="index.html">Home</a>
//           </li>
//           <li className="breadcrumb-item active" aria-current="page">
//             Sigh Up
//           </li>
//         </ul>
//       </nav>
//     </div>
//   </div>
// </section> */}

//     <div className='signformtc'>
//     <form onSubmit={handleSubmit} className="signup-form">
//     <center><h2>Signup</h2></center><br/>
//       <label>
//         <input type="text" value={fullName} placeholder='Name' onChange={(e) =>setFullName(e.target.value)} />
//       </label>
//       <label>
//         <input type="tel" value={phoneNumber} placeholder='Phone' onChange={(e) => setPhoneNumber(e.target.value)} />
//       </label>
//       <label>
//         <input type="email" value={email} placeholder='Email@gmail.com' onChange={(e) =>setEmail(e.target.value)} />
//       </label>
//       <label>
//         <input type="password" value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
//       </label>
//       <label>
//         <input type="password" value={confirmPassword} placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)} />
//       </label>
//       <button type="submit"style={{marginTop:"40px", width:"450px" , marginLeft:"-20px"}}>Sign Up</button>
//     </form>
//     </div>
//     </div>
//   );
// }

// export default Signup;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./SignupStyle.css";
const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!fullName.trim()) {
      errors.fullName = 'Full name is required.';
    }

    if (!email.trim()) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address.';
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required.';
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number.';
    }

    if (!password.trim()) {
      errors.password = 'Password is required.';
    } else if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters long.';
    }

    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'Confirm password is required.';
    } else if (confirmPassword !== password) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      // Perform signup logic
      // You can make an API call or implement your own signup functionality here
      // For simplicity, let's just display a success message
      alert('Signup successful!');
      setFullName('');
      setEmail('');
      setPhoneNumber('');
      setPassword('');
      setConfirmPassword('');
      setErrors({});
    }
  };

  return (
    <div>
    <section className="page-title" style={{ backgroundColor: "#20255A",marginTop:"70px" , padding:"50px"}}>
         <div className="auto-container" style={{marginLeft:"510px"}}>
           <h1 className="d-none d-lg-block d-xl-block d-md-block" style={{color:"white"}}>SIGNUP</h1>
           <ul className="bread-crumb clearfix">
             <li>
              <Link to="/" style={{marginLeft:"50px"}}>Home</Link>
            </li>
  
          </ul>
        </div>  
             </section>
          <div style={{backgroundColor:"#a6b0bf",border: '2px solid black',marginLeft:"350px",marginRight:"400px",marginTop:"10px"}}>  
      <h2  style={{ marginLeft:"250px",color:"black" }}>Signup</h2>

      <form onSubmit={handleSubmit} style={{marginLeft:"100px"}}>
        <div >
          <label  >Full Name:</label>
          <input
            type="text"
            id="fullName"
            placeholder='fullName'
            value={fullName}
            style={{border: '2px solid black'}}
            onChange={(e) => setFullName(e.target.value)}
          />
          {errors.fullName && <p  style={{ color: 'red' }}>{errors.fullName}</p>}
        </div>
        <div>
          <label htmlFor="email" >Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            placeholder='email'
            style={{border: '2px solid black'}}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
         <div>
          <label htmlFor="phoneNumber" >Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            placeholder='phoneNumber'
            style={{border: '2px solid black'}}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          {errors.phoneNumber && <p style={{ color: 'red' }} >{errors.phoneNumber}</p>}
        </div>
         <div>
          <label htmlFor="password" >Password:</label>
          <input
            type="password"
            id="password"
            placeholder='Password'
            value={password}
            style={{border: '2px solid black'}}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
        </div>
         <div>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            placeholder='confirmPassword'
            style={{border: '2px solid black'}}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.confirmPassword && <p style={{ color: 'red' }}>{errors.confirmPassword}</p>}
        </div>
        <button type="submit" style={{marginBottom:"10px",border: '2px solid black',color:"white",backgroundColor:"black"}}>Signup</button>
      </form>
      </div> 
    </div>
  );
};

export default Signup;
