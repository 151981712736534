import React from 'react'
import "./ReviewStyle.css"
const Review = () => {
  return (
    <div style={{ marginTop:"-60px"}}>
    <div className='frform' style={{ border: '7px solid white',margin:"40px",marginTop:"120px",position:'auto'}}>
    <div>
      <div
  id="courseReview"
  className="reviews sticky_place"
  data-sticky-name="Reviews"
  data-test="reviewMain"
>
  <div className="container" style={{backgroundColor:'"#61696E'}}>
    <h2>
      Full Stack Developer Course <b>Learner Reviews</b>
    </h2>
    <div id="reviewsContent" data-test="reviewSlider">
      <div className="frs-slider">
        <div className="card slid-detail" id="dual-review-content">
          <div className="ul-iners ">
            <ul
              className="c_list"
              style={{
                position: "relative",
                transform: "translate3d(0px, 0px, 0px)",
                width: 6000,
                transition: "all 0.3s ease 0s"
              }}
            >
              <li className="c_list_item c_index_0" data-index={0}>
                <div className="review-card">
                  <a
                    href="https://www.linkedin.com/in/pooja-choudhary-8791891aa/"
                    rel="noreferrer nofollow"
                    className="profile"
                    target="_blank"
                  >
                    <img
                      className="blend-mode"
                      src="https://www.simplilearn.com/ice9/testimonial_images/pooja.jpg"
                      width={80}
                      height={80}
                      alt="Pooja Choudhary"
                      title="Pooja Choudhary"
                      data-gumlet="false"
                      loading="lazy"
                    />
                  </a>
                  <div className="info">
                    <h3>Pooja Choudhary</h3>
                    <span>Software Engineer</span>
                    <span className="stars">
                      <span className="star_in" style={{ width: "84.9px" }} />
                    </span>
                  </div>
                  <p>
                    I think the course was good overall and it definitely helped
                    me upskill. Since I am a fresher, I am glad that I was also
                    to get my first job after completing the course.
                  </p>
                </div>
              </li>
              <li className="c_list_item c_index_1" data-index={1}>
                <div className="review-card">
                  <a
                    href="https://www.linkedin.com/in/vidya-s-a3a35520b/"
                    rel="noreferrer nofollow"
                    className="profile"
                    target="_blank"
                  >
                    <img
                      className="blend-mode"
                      src="https://www.simplilearn.com/ice9/testimonial_images/vidya.jpg"
                      width={80}
                      height={80}
                      alt="Vidya S"
                      title="Vidya S"
                      data-gumlet="false"
                      loading="lazy"
                    />
                  </a>
                  <div className="info">
                    <h3>Vidya S</h3>
                    <span>Software Developer</span>
                    <span className="stars">
                      <span className="star_in" style={{ width: "84.9px" }} />
                    </span>
                  </div>
                  <p>
                    My learning experience with simplilearn in the Java Full
                    Stack Developer Program was great. I am thankful to
                    Simplilearn for providing job opportunity because of which I
                    got placed in a very good organisation.
                  </p>
                </div>
              </li>
              <li className="c_list_item c_index_2" data-index={2}>
                <div className="review-card">
                  <a
                    href="https://www.linkedin.com/in/kurupraj/"
                    rel="noreferrer nofollow"
                    className="profile"
                    target="_blank"
                  >
                    <img
                      className="blend-mode"
                      src="https://www.simplilearn.com/ice9/testimonial_images/129239.jpeg"
                      width={80}
                      height={80}
                      alt="Raj Kurup"
                      title="Raj Kurup"
                      data-gumlet="false"
                      loading="lazy"
                    />
                  </a>
                  <div className="info">
                    <h3>Raj Kurup</h3>
                    <span>
                      Application Development Senior Director at CIGNA
                    </span>
                    <span className="stars">
                      <span className="star_in" style={{ width: "84.9px" }} />
                    </span>
                  </div>
                  <p>
                    Simplilearn team is the best. I love the format and
                    logistics of Simplilearn so much that I would chose them for
                    future courses at any cost rather than take anything else.
                    Awesome guys.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="view-more-btn-fixed ">
            <span className="c_arrow c_prev hide-btn">prev</span>
            <span className="c_arrow c_next">Next</span>
            <div className="view-more-btn">
              <span
                className="c_bullet_mobile_dual-review-content_0 active_tab"
                data-slider-index={0}
              />
              <span
                className="c_bullet_mobile_dual-review-content_1"
                data-slider-index={1}
              />
              <span
                className="c_bullet_mobile_dual-review-content_2"
                data-slider-index={2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    </div>
    </div>
  )
}

export default Review
