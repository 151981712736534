import React from 'react';
import "./Aboutus.css";

const Aboutus = () => {
  return (
    <div className="aboutus-container-about25">
      <div className="aboutus-section-about25">
        <div className="aboutus-heading-3d">About Us</div>
        <img 
          src="https://cdn.pixabay.com/photo/2022/10/31/05/14/skyscrapers-7558938_1280.jpg" 
          alt="Full Screen Background" 
          className="aboutus-fullscreen-img-about25"
        />
      </div>
      <div className="aboutus-full-width-text-about25">
        <p className="aboutus-paragraph-about25">
          BENIT SOFTETCH is newly emerging one stop solution provider for all your IT needs.
          It Currently focuses on Consulting services, Application Implementations, Support 
          and Maintenance. We provide a complete suite of All IT services to our clients.
          We provide innovative technology solutions to the business challenges you face.
          Our industry specialists and information technologists work closely with you 
          to create optimum solutions, specifically tailored to your company.
          BENIT SOFTECH is a distinguished IT company specializing in mobile app development,
          web development, server development, and the cutting-edge realm of blockchain technology.
        </p>
      </div>
      <div className="aboutus-content-about25">
        <div className="aboutus-text-left-about25">
          <p className="aboutus-paragraph-about25">
            With an unwavering commitment to excellence, we leverage our extensive expertise and 
            technical prowess to craft intricate and innovative solutions for our discerning clientele.
            Our team of seasoned professionals excels in transforming complex ideas into seamless,
            feature-rich mobile applications that captivate users and elevate business performance.
            Moreover, our proficiency in web development enables us to deliver sophisticated and 
            dynamic websites that resonate with modern design trends while maximizing functionality.
            In the realm of server development, we engineer robust and scalable architectures that 
            ensure optimal performance and efficiency. Furthermore, as pioneers in blockchain technology,
            we harness the power of decentralized systems and smart contracts to revolutionize
            industries and empower businesses with secure and transparent solutions. At BENIT SOFTETCH,
            we combine meticulous attention to detail with an unwavering passion for innovation,
            enabling us to deliver exceptional results in the ever-evolving landscape of technology.
            Driven by our relentless pursuit of technological advancement, we push the boundaries
            of what is possible, consistently surpassing client expectations and setting new industry
            benchmarks. With our comprehensive suite of services and a client-centric approach.
            BENIT SOFTECH is the trusted partner you need to unlock the full potential of 
            digital transformation and drive your business to unprecedented heights of success.
          </p>
        </div>
        {/* <img 
          src="https://media.istockphoto.com/id/1368759720/vector/ai-artificial-intelligence-letters-3d-virtual-assistant-app-geometric-symbol-robot-support.jpg?s=612x612&w=0&k=20&c=ZVVpUgBA4mJF0FhTYnKFGSilSX-bDTaiqV6SS2WlJfY=" 
          alt="Right Side Image" 
          className="aboutus-side-img-about25"
        /> */}
      </div>
      <div className="aboutus-content-about25">
        <img 
          src="https://media.istockphoto.com/id/1772272183/vector/us-dollar-symbol-on-city.jpg?s=612x612&w=0&k=20&c=lvby0m226KafV2cOi9OY7O9UAaAu89Tkkz48769yVC8=" 
          alt="Left Side Image" 
          className="aboutus-side-img-about25 fixed-left-img-about25"
        />
        <div className="aboutus-text-content-about25">
          <div className="aboutus-text-top-about25">
            <p className="aboutus-paragraph-about25">
              BENIT SOFTECH is into a strategic partnership with a large number of business conglomerates. We identify the available opportunities with our clients and help them find the right talent which would eventually help them achieve their organisational goals.  We act as a link between the job seeker and employer to help them find the meaningful and appropriate job opportunity. Our solutions are tailored as per your business needs. We’re on a mission to find, attract, and bring aboard the best and brightest – superstars who are also great team players and leaders. We are in a constant lookout for talented, forward-thinking people who share in the common goal of driving human progress forward.
              BENIT SOFTECH is one of the leading recruitment service providers in India. We are a preferred talent acquisition partner to organizations of high repute across various industries.  Over the years, we have served to a large number of corporate clients and conglomerates.  Imbibing the values of transparency, reliability and growth, we have been instrumental in the growth journey of our esteemed clients.  
              We are into strategic alliance with clients from different sectors and industries to help them grab the high quality talent available in the job market, thus addressing their most critical challenges.  We have consistently and successfully catered to our clients’ needs over the period of time which has helped us create a niche in the hiring industry.  As a leader in the hiring industry, we have always strived to support the huge talent pool that our country possess, by connecting them with their most appropriate employer.  
              We are a team of experts working relentlessly to align diversified HR needs of our clients. As a talent acquisition firm, we are catering to our clients with entry level talent pool to executive level.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
