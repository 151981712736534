// import React from "react"
import "./LoginStyle.css"
import { Link } from "react-router-dom"
import React, { useState } from 'react';

  const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form inputs
    if (!email || !password) {
      setError('Please enter your email and password');
      return;
    }
    alert('login sucessfully')        
     setEmail('');
    setPassword('');
    setError('');
  };

  return (
    <div>

<section className="page-title" style={{ backgroundColor: "#122C3F",marginTop:"90px" , padding:"50px"}}>
        <div className="auto-container" style={{marginLeft:"520px"}}>
          <h1 className="d-none d-lg-block d-xl-block d-md-block" style={{color:"white"}}>LOGIN</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/" style={{marginLeft:"35px"}}>Home</Link>
            </li>
  
          </ul>
        </div>
      </section>

     <div className='frform'
      style={{ backgroundColor:'#a6b0bf', border: '7px solid black',margin:"40px", 
      marginTop:"10px",position:'auto' , width:"600px" , marginLeft:"350px"}}>

    <div className="Auth-form-container" style={{marginLeft:"-420px" , marginTop:"-40px"}}>
      <form  onSubmit={handleSubmit} >
        <div className="Auth-form-content" >
          <h4 className="Auth-form-title" style={{marginLeft:"150px"}}>Login</h4>
          <div className="form-group mt-3" style={{paddingLeft:"60px"}}>
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
                value={email} onChange={handleEmailChange}
            />
          </div>
          <div className="form-group mt-3" style={{paddingLeft:"60px"}}>
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
                 value={password} onChange={handlePasswordChange}
            />
          </div>
          <div className="d-grid gap-2 mt-3" style={{paddingLeft:"60px"}}>
            <button type="submit" className="btn btn-primary" style={{marginTop:"20px"}}>
              Submit
            </button>
          </div>
            {error && <div style={{ color: 'red' , marginLeft:"62px" , marginTop:"20px" }}>{error}</div>}
            <div style={{marginTop:"15px"}}>
        <Link to ="/Forgetpassword" style={{marginLeft:"62px" , color:"black"}}>Forgot Password?</Link>
     </div>
          {/* <p className="forgot-password text-right mt-2" style={{paddingLeft:"60px"}}>
            Forgot <link to="/" onClick={handleForgotPassword>password?</link>
          </p> */}
        </div>
      </form>
    </div>
    </div>
    </div>
  )
}
export default Login 


