import React, { useState } from 'react';
import './ContactusStyle.css';

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.contact) newErrors.contact = 'Phone or Email is required';
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit form data
      console.log('Form Data Submitted', formData);
      // Reset form fields
      setFormData({
        name: '',
        contact: '',
        subject: '',
        message: ''
      });
      setErrors({});
    }
  };

  return (
    <div className="containerCont25">
      <div className="headingCont25">
        <h1>Let’s Start Working Together. Get in Touch with Us!</h1>
        <p>Reach US to discuss about our Dedicated Client-centric service today.</p>
      </div>
      <form className="formCont25" onSubmit={handleSubmit}>
        <div className="form-groupCont25">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span className="errorCont25">{errors.name}</span>}
        </div>
        <div className="form-groupCont25">
          <label htmlFor="contact">Phone/Email</label>
          <input
            type="text"
            id="contact"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
          />
          {errors.contact && <span className="errorCont25">{errors.contact}</span>}
        </div>
        <div className="form-groupCont25">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && <span className="errorCont25">{errors.subject}</span>}
        </div>
        <div className="form-groupCont25">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && <span className="errorCont25">{errors.message}</span>}
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Contactus;
