import React from 'react'
import "./Stats.css"
import { colors } from '@material-ui/core'

const Stats = () => {
  return (
    <div>
      <section className="stats-container">
        <div className="stats-grid">
          <div className="stats-item">
          <p className="stats-label">Years Of Experience</p>
            <p className="stats-number">20+</p>
           
          </div>
          <div className="stats-item">
          <p className="stats-label">Skilled Professionals</p>
            <p className="stats-number">500+</p>
           
          </div>
          <div className="stats-item">
          <p className="stats-label">Cloud Servers</p>
            <p className="stats-number">500+</p>
          
          </div>
          <div className="stats-item">
          <p className="stats-label">Customer</p>
            <p className="stats-number">40k+</p>
           
          </div>
        </div>
      </section>
    </div>
  )
}

export default Stats
