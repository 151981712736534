import React from 'react'
import HomeImg from './Homeimg'
// import Service from './Services/Service'
// import Careers from './CAREERS/Careers'
// import Values from './Values'
// import Review from './Review'
import Industry from './Industries/Industry'
import Business from './Business/Business'
import ServiceM from './Services/ServiceM'
import Testimonials from './Testimonials/Testimonials'
import Stats from './Stats/Stats'


const Homepage = () => {
  return (
    <div>
    <HomeImg/>
    <Business/>
    <ServiceM/>
    <Testimonials/>
    {/* <Stats/> */}
    {/* <Industry/> */}
   {/* <Service/> */}
   {/* <Values/> */}
   {/* <Review/> */}
  
   
    </div>
  )
}

export default Homepage
